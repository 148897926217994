import classNames from 'classnames';
import { BaseTextFieldProps, InputField } from './InputField';

export const TextField = ({ maxLength, placeholder, formControlClassName, ...props }: BaseTextFieldProps) => {
    return (
        <InputField {...props} renderElement={(formik, field, meta) => {
            const withError = meta.touched && meta.error;
            return (
                <div className="data-item-col">
                    <input
                        className={classNames('form-control', formControlClassName, { 'is-invalid': withError })}
                        {...field}
                        maxLength={maxLength}
                        value={field.value || ''}
                        placeholder={placeholder}
                    />
                    {withError && <div className="form-error">{meta.error}</div>}
                </div>
            )
        }} />
    );
}
