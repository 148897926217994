import { BaseFieldProps, InputField } from "./InputField";
import { constants } from "../../../constants/constants";
import { DayPickerInput } from "../../controls/DayPickerInput";
import moment from "moment";
import classNames from "classnames";

interface DateFieldProps extends BaseFieldProps {
    placeholder?: string;
    maximumDate?: Date;
    emptyIfNull?: boolean;
    dateFormat?: string;
    dateInputFormat?: string[];
    withCustomView?: boolean;
    disabled?: boolean;
}

export const DateField = ({
    placeholder = "MM/DD/YYYY",
    maximumDate,
    emptyIfNull,
    dateFormat = constants.dateShortFormat,
    dateInputFormat = [constants.dateShortFormat, constants.dateFormat, constants.dateFormat5],
    withCustomView,
    disabled,
    ...props
}: DateFieldProps) => {
    return (
        <InputField
            {...props}
            renderElement={(formik, field, meta) => (
                <div className="data-item-col">
                    <div className="">
                        <DayPickerInput
                            disabled={disabled}
                            selectedDate={field.value ? moment(
                                field.value
                            ).toDate() : undefined}
                            placeholder="MM/DD/YYYY"
                            maximumDate={maximumDate}
                            emptyIfNull={emptyIfNull}
                            dateFormat={dateFormat}
                            dateInputFormat={dateInputFormat}
                            onDayChange={(day?: Date | string) =>
                                formik.setFieldValue(
                                    field.name,
                                    day
                                        ? moment(day)
                                              .startOf("day")
                                              .utcOffset(0, true)
                                              .format()
                                        : null
                                )
                            }
                            toMonth={maximumDate}
                            className={classNames("form-control", { "is-invalid": meta.touched && meta.error })}
                            withCustomView={withCustomView}
                        />
                    </div>
                    {meta.touched && meta.error && (
                        <div className="form-error">{meta.error}</div>
                    )}
                </div>
            )}
        />
    );
};
